<template>
    <div class="m2 wrapper wrapper--default">
        <h3 class="text-center m-2">Voertuigen van leverancier: <router-link :to="{
            name: 'leverancier',
            params: { id: levnr },
        }">
            {{ levnr }}</router-link></h3>
        <hr>
        <Loading v-if="loading" />
        <div class="p-2 flexbox _row" v-else>
            <div v-for="(status, key) in lev_items_stock_sold" :key="key" class="flex-grow-1 m-2">
                <h3 v-if="key == 'items_in_s'" class="text-center">Voertuigen in S</h3>
                <h3 v-else class="text-center">Voertuigen in V</h3>
                <hr>
                <table :class="`w-100 table__border--default`">
                    <thead :class="`table__head--default`">
                        <th>itemnummer</th>
                        <th>Bu</th>
                        <th>Merk</th>
                        <th>Kenteken</th>
                        <th>Reg jaar</th>
                        <th>Vraagprijs</th>
                        <th>Consignatie</th>
                        <th>Gereserveerd</th>
                    </thead>
                    <tbody>
                        <tr v-for="(item, key) in status" :key="key">
                            <td class="text-center table__cell--default">
                                <ImageHover :itemnummer="item.nummer" :bu="item.bu" />
                            </td>
                            <td class="text-center table__cell--default">{{ item.bu }}</td>
                            <td class="table__data--left table__cell--default">{{ item.merknaam }}</td>
                            <td class="table__data--left table__cell--default">{{ item.kenteken }}</td>
                            <td class="text-center table__cell--default">{{ item.regjaar }}</td>
                            <td class="table__data--right table__cell--default">€ {{ item.vraagprijs }}</td>
                            <td class="table__data--right table__cell--default">{{ item.consignatie }}</td>
                            <td class=" table__cell--default">{{ item.gereserveerd }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import Loading from '@/components/Loading.vue';
import request from '@/functions/request.js';
import ImageHover from '@/components/ImageHover.vue'

export default {
    props: ['levnr'],
    components: { Loading, ImageHover },
    data: () => ({
        lev_items_stock_sold: null,
        loading: true
    }),
    created() {
        this.getData(this.levnr)
    },
    methods: {
        getData(levnr) {
            request(`suppliers-stock-sold-items/${levnr}`, "GET").then(({ lev_items_stock_sold }) => {
                this.lev_items_stock_sold = lev_items_stock_sold;
                this.loading = false;
            })
        },
    }
};
</script>